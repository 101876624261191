<template>

  <div id="home">

    <vue-headful
      title="Home - Hair Design by Barry"
      description="Hello, I'm Barry Cook HAIR & BEAUTY STYLIST I've been in the hair designing business for over 30 years now."
    />

    <Navbar></Navbar>

    <div class="section1 pb-4" style="margin-bottom: 5%;">

      <div class="container content fade-in-left" style="margin-bottom: 5%;">

        <h1 class="title heading">
           Hello,
          <br />
           I'm Barry Cook.
        </h1>

        <div class="wrap">

          <h2 class="subtitle highlight">

            <strong class="pl-2 pb-2 pt-2">HAIR &amp; BEAUTY STYLIST</strong>

          </h2>

        </div>

        <div class="row mt-4">

          <div class="col-sm">

            <p>
               I’ve been in the hair designing business for over 35 years now. I
              started at the age of 19, attending the Pennsylvania Academy of Cosmetology.
              I worked my first year in Pennsylvania and then moved to Atlanta, GA
              in 1989 where I worked as a master stylist in some of the Top salons
              in the city. I started my own business in 1999 in Atlanta and then
              met my Swiss partner in 2000.
            </p>

          </div>

          <div class="col-sm">

            <p>
               I now have lived and operated my own salon in Basel since 2007. I
              love bringing out the best in my clients and enjoy their happy faces
              when I’m finished making them look their best.
            </p>

          </div>

        </div>

        <a href="https://www.facebook.com/hairdesignbybarry/" target="_blank">

          <i class="fab fa-facebook fa-3x" style="color:white;"></i>

        </a>

      </div>

    </div>

    <div class="section2" style="text-align:center;">

      <div class="container content fade-in-left">

        <h1 class="title heading dark-text">Exclusive Products</h1>

        <div class="row">

          <div class="col">

            <img src="../assets/brands/janeiredale.png" style="width:140px;" />

          </div>

          <div class="col">

            <img
              src="../assets/brands/belief-plus.png"
              style="width:140px;margin-top:32%"
            />

          </div>

          <div class="col">

            <img src="../assets/brands/davines.png" style="width:140px;" />

          </div>

          <div class="col">

            <img src="../assets/brands/confortzone.png" style="width:140px;" />

          </div>

          <div class="col">

            <img
              src="../assets/brands/skin-regimen.png"
              style="width:140px;margin-top:25%;"
            />

          </div>

          <div class="col">

            <img src="../assets/brands/KevinMurphy.png" style="width:140px;" />

          </div>

        </div>

      </div>

    </div>

    <div class="section2" style="text-align:center;">

      <div class="container content fade-in-left">

        <h1 class="title heading dark-text">Services I have provided</h1>

        <p class="dark-text">
           From men’s and women’s haircuts and hair color services to men’s cuts
          and styles, I provide a large variety of services to everyone. Do you need
          a new style? I also collaborate with highly qualified beauty professionals
          in my salon. We offer a wide variety of services including manicure/pedicure,
          facials, waxing, massage and makeup services. Just send a message or give
          a call!
        </p>

        <div class="row pt-4">

          <div class="col">

            <img
              src="../assets/services/wash-hair-style.jpg"
              style="width:140px;border-radius: 50%;"
            />

            <h2 class="subtitle dark-text pt-1">

              <strong>Ladies Wash, Haircut &amp; Style</strong>

            </h2>

            <p class="dark-text pt-2">
               From simple to extreme, wash, cut and style will leave you ready for
              anything, and you’ll look like it too!
            </p>

            <p class="dark-text pt-6">

              <strong>140*</strong>

            </p>

          </div>

          <div class="col">

            <img
              src="../assets/services/wash-hair-gents.jpg"
              style="width:140px;border-radius: 50%;"
            />

            <h2 class="subtitle dark-text pt-1">

              <strong>Gentlemen Wash &amp; Haircut</strong>

            </h2>

            <p class="dark-text pt-2">
               Clean and simple cuts or bold and new styles, either way, you will
              leave looking fresher then ever before!
            </p>

            <p class="dark-text pt-6">

              <strong>85*</strong>

            </p>

          </div>

          <div class="col">

            <img
              src="../assets/services/double-process-color.jpg"
              style="width:140px;border-radius: 50%;"
            />

            <h2 class="subtitle dark-text pt-1">

              <strong>Double Process Color</strong>

            </h2>

            <p class="dark-text pt-2">
               That new color you were looking for with the highlights or lowlights
              to follow! The double process color allows you be a trend setter in
              your own way!
            </p>

            <p class="dark-text pt-6">

              <strong>160*</strong>

            </p>

          </div>

        </div>

        <div class="pt-2 pb-6">

          <p class="dark-text" style="text-align: center;"> * = and up. </p>

        </div>

        <router-link to="/services" class="btn btn-info pt-2">
          View More
        </router-link>

      </div>

    </div>

    <div class="devider"></div>

    <div class="section1 pb-4">

      <div class="container content fade-in-left" style="margin-bottom: 5%;">

        <h1 class="title heading">Client Testimonials</h1>

        <div class="row pt-4">

          <div class="col">

            <div class="card" style="width: 18rem;">

              <!-- <div class="card-img-top" style="text-align:right;">
                  <i class="fas fa-comment fa-3x"></i>
                </div> -->

              <div class="card-body">

                <p class="card-text dark-text">
                   I have been visiting Barry since I relocated to Basel 2 years
                  ago, and I wouldn’t go anywhere else! Barry is not only a phenomenal
                  hair dresser, but I thoroughly enjoy the conversation and the travel
                  tips. I highly, highly recommend a cut and color with Barry!
                </p>

                <h5 class="card-title dark-text">- Kristen Wall</h5>

              </div>

            </div>

          </div>

          <div class="col" style="">

            <div class="card" style="width: 18rem;background-color:black;">

              <!-- <div class="card-img-top" style="text-align:right;">
                  <i class="fas fa-comment fa-3x"></i>
                </div> -->

              <div class="card-body">

                <p class="card-text">
                   Barry is the absolute best- a genius with hair and a delight to
                  speak with. I just had my first appointment with him and I will
                  definitely be back. I’d been nervous to get my hair cut, but Barry
                  put me at ease and mastered the classy look I was hoping for. Thanks
                  Barry!!!
                </p>

                <h5 class="card-title">- Elisa Galey</h5>

              </div>

            </div>

          </div>

          <div class="col">

            <div class="card" style="width: 18rem;">

              <!-- <div class="card-img-top" style="text-align:right;">
                  <i class="fas fa-comment fa-3x"></i>
                </div> -->

              <div class="card-body">

                <p class="card-text dark-text">
                   I highly recommend Hair Design by Barry! Barry is very professional
                  and highly talented, with many years of experience and training
                  in the business. He is amazing at what he does! His haircut always
                  brings out the best in my naturally curly hair even with low maintenance.

                </p>

                <h5 class="card-title dark-text">- Maria Santos</h5>

              </div>

            </div>

          </div>

        </div>

        <div class="devider"></div>

        <a
          href="https://www.facebook.com/hairdesignbybarry/reviews/"
          class="btn btn-info"
        >
          Read More
        </a>

      </div>

    </div>

    <Footer></Footer>

  </div>

</template>

<script>
   export default { name: "Home", components: {}, };
</script>

<style>
  .content {
    padding-top: 7%;
  }
  .section1 {
    background: #304352; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #212121,
      #9c9fa2
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #212121,
      #9c9fa2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .title {
    font-size: 50px;
  }
  .subtitle {
    font-size: 25px;
  }
  .highlight {
    background-color: #fff;
    color: #000 !important;
    padding: 5px 5px 5px 5px;
    max-width: 350px;
  }

  .btn-info {
    background: rgba(0, 0, 0, 1);
    color: white;
    border: #000 solid 1px;
    padding: 8px 15px 8px 15px;
    border-radius: 0.2rem;
    transition: 0.2s;
  }
  .btn-info:hover {
    opacity: 0.7;
    color: white;
    background-color: rgba(84, 89, 95, 1);
    border-color: #54595f;
    text-decoration: none;
    cursor: pointer;
  }
  .btn-info:focus {
    opacity: 0.7;
    color: #54595f;
    background-color: #ffffff !important;
    border-color: #000;
    box-shadow: 0 0 0 0.2rem #54595f;
    text-decoration: none;
    cursor: pointer;
  }
  .devider {
    margin-top: 2%;
    margin-bottom: 2%;
  }
</style>

