<template>
  <div id="recommendations">
    <vue-headful
      title="Recommendations - Hair Design by Barry"
      description="Hello, I'm Barry Cook HAIR & BEAUTY STYLIST I've been in the hair designing business for over 30 years now."
    />
    <Navbar></Navbar>

    <div class="section1 pb-4" style="margin-bottom: 5%;">
      <div class="container content fade-in-left" style="margin-bottom: 5%;">
        <h1 class="title heading">
          My Favorite Businesses In Basel
        </h1>
      </div>
    </div>

    <div class="section2">
      <div class="container fade-in-left">
        <h1 class="subtitle heading dark-text pb-4">
          The first 3 recommendations collaborate with me in my salon.
        </h1>

        <div class="row" style="text-align:center;">
          <!-- <div class="col">
              <p class="subtitle dark-text">Hair &amp; Make-up</p>
              <p class="dark-text">Victoria Nelson</p>
              <a href="http://stylepixiesalon.ch/" target="_blank">stylepixiesalon.ch</a>
            </div> -->
          <div class="col">
            <p class="subtitle dark-text">Beauty &amp; Wellness</p>
            <p class="dark-text">Barbara Szymaniak</p>
            <p class="dark-text">Krista Kiley</p>
            <a href="http://www.baselwellness.ch/" target="_blank"
              >baselwellness.ch</a
            ><br />
            <a href="https://three-six-o.com" target="_blank"
              >three-six-o.com</a
            >
          </div>
          <div class="col">
            <p class="subtitle dark-text">Hairstylist</p>
            <p class="dark-text">Stephanie Galbraith</p>
            <p class="dark-text">Katie Kelly</p>
            <a
              href="https://www.facebook.com/sws.styleswithstephanie/"
              target="_blank"
              >View Stephanie's Facebook Page</a
            ><br />
            <a href="http://hairandbeautybyktkelly.com/" target="_blank"
              >hairandbeautybyktkelly.com</a
            >
          </div>
          <div class="col">
            <p class="subtitle dark-text">Reflexology &amp; Energy Work</p>
            <p class="dark-text">Esther Leugger</p>
            <a href="http://www.estherleugger.com/" target="_blank"
              >estherleugger.com</a
            >
          </div>
        </div>

        <div class="devider"><hr /></div>

        <div class="row" style="text-align:center;">
          <div class="col">
            <p class="subtitle dark-text">
              Personal training/ Pain Therapy/ Massage
            </p>
            <p class="dark-text">Angelica &amp; Andreas Rüsch</p>
            <a href="http://metamove.ch/" target="_blank">metamove.ch</a>
          </div>
          <div class="col">
            <p class="subtitle dark-text">
              Taxes / Finance / Wine / Alexander Technique
            </p>
            <p class="dark-text">Mo Ruoff</p>
            <a href="http://www.sonnenreife.com/" target="_blank"
              >sonnenreife.com</a
            >
          </div>
          <div class="col"></div>
        </div>

        <div class="devider"><hr /></div>

        <div class="row" style="text-align:center;">
          <!-- <div class="col">
                <p class="subtitle dark-text">Tai Chi/Dance</p>
                <p class="dark-text">Esther Sutter</p>
                <a href="http://tanzundtaiji.ch/" target="_blank">tanzundtaiji.ch</a>
            </div> -->
          <div class="col"></div>
          <div class="col"></div>
        </div>
      </div>
    </div>

    <div class="devider"></div>

    <Footer></Footer>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
  };
</script>
<style>
  .content {
    padding-top: 7%;
  }
  .section1 {
    background: #304352; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #212121,
      #9c9fa2
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #212121,
      #9c9fa2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .title {
    font-size: 50px;
  }
  .subtitle {
    font-size: 25px;
  }
  .highlight {
    background-color: #fff;
    color: #000 !important;
    padding: 5px 5px 5px 5px;
    max-width: 350px;
  }

  .btn-info {
    background: rgba(0, 0, 0, 1);
    color: white;
    border: #000 solid 1px;
    padding: 8px 15px 8px 15px;
    border-radius: 0.2rem;
    transition: 0.2s;
  }
  .btn-info:hover {
    opacity: 0.7;
    color: white;
    background-color: rgba(84, 89, 95, 1);
    border-color: #54595f;
    text-decoration: none;
    cursor: pointer;
  }
  .btn-info:focus {
    opacity: 0.7;
    color: #54595f;
    background-color: #ffffff !important;
    border-color: #000;
    box-shadow: 0 0 0 0.2rem #54595f;
    text-decoration: none;
    cursor: pointer;
  }
  .devider {
    margin-top: 2%;
    margin-bottom: 2%;
  }
</style>
