<template>

  <div id="services">

    <vue-headful
      title="Services - Hair Design by Barry"
      description="Hello, I'm Barry Cook HAIR & BEAUTY STYLIST I've been in the hair designing business for over 30 years now."
    />

    <Navbar></Navbar>

    <div class="section1 pb-4" style="margin-bottom: 5%;">

      <div class="container content fade-in-left" style="margin-bottom: 5%;">

        <h1 class="title heading"> My Services </h1>

      </div>

    </div>

    <div class="section2">

      <div class="container content fade-in-left">

        <h1 class="title heading dark-text">Ladies</h1>

        <div class="row">

          <div class="col">

            <img src="../assets/examples/1.jpg" style="width:240px;" />

          </div>

          <div class="col">

            <h2 class="subtitle dark-text">Wash &amp; Blowdry</h2>

            <p class="dark-text">80*</p>

            <hr />

            <h2 class="subtitle dark-text">Wash, Haircut &amp; Style</h2>

            <p class="dark-text">140*</p>

          </div>

          <div class="col">

            <p class="dark-text">
               It doesn’t matter if it’s a wash and blowdry or you want to add a
              little bit of style too, once you sit in the chair, you won’t regret
              your decision, ensuring you’ll leave happier than ever!
            </p>

          </div>

        </div>

      </div>

      <div class="container content fade-in-left">

        <h1 class="title heading dark-text">Gentlemen</h1>

        <div class="row">

          <div class="col">

            <img src="../assets/examples/2.jpg" style="width:240px;" />

          </div>

          <div class="col">

            <h2 class="subtitle dark-text">Wash &amp; Haircut</h2>

            <p class="dark-text">90*</p>

          </div>

          <div class="col">

            <p class="dark-text">
               There’s nothing better than a smooth cut and a nice wash. Have a specific
              style? Great! I’ve done them all!
            </p>

          </div>

        </div>

      </div>

      <div class="container content fade-in-left">

        <h1 class="title heading dark-text">Colors &amp; Highlights</h1>

        <div class="row">

          <div class="col">

            <img src="../assets/examples/3.jpg" style="width:240px;" />

          </div>

          <div class="col">

            <h2 class="subtitle dark-text">One Process</h2>

            <p class="dark-text">110*</p>

            <hr />

            <h2 class="subtitle dark-text">
               Double Process (Bleach &amp; Tone)
            </h2>

            <p class="dark-text">200*</p>

            <hr />

            <h2 class="subtitle dark-text">Foil Highlight (Partial)</h2>

            <p class="dark-text">170*</p>

            <hr />

            <h2 class="subtitle dark-text">Foil Highlight (Full)</h2>

            <p class="dark-text">190*</p>

          </div>

          <div class="col">

            <p class="dark-text">
               From one process to full foil highlights, I have you covered, in color
              that is! You’ll look stunning with whatever option you choose!
            </p>

          </div>

        </div>

      </div>

      <div class="container content fade-in-left">

        <h1 class="subtitle heading dark-text">Other Services</h1>

        <p class="dark-text">

          <strong>On Request</strong>
           (With Prior Consultation)
        </p>

        <p class="dark-text">

          <strong>Belief + start up renew scalp treatment</strong>
           - 65*
          <br />
           This innovative product helps remove from the skin chemical impurities
          and deposits such as sebum, toxins, flaking and pollutants that clog hair
          follicles.
        </p>

        <div class="devider"><hr /></div>

        <h1 class="subtitle heading dark-text">Consultations</h1>

        <p class="dark-text">

          <strong>On Request (New Clients Only)</strong>
           Free
        </p>

        <div class="devider"><hr /></div>

        <h1 class="subtitle heading dark-text">**DISCLAIMER**</h1>

        <p class="dark-text">
           Based on length, time and products needed to complete service.
          <br />
           All color services include wash. Style not included.
          <br />
           All prices in Swiss Francs.
          <br />

          <br />
           If you decide to cancel your appointment, a 48 hour notice is required
          or 50% of the scheduled services will be charged on your next appointment.

        </p>

      </div>

    </div>

    <div class="devider"></div>

    <Footer></Footer>

  </div>

</template>

<script>
   export default { name: "Home", components: {}, };
</script>

<style>
  .content {
    padding-top: 7%;
  }
  .section1 {
    background: #304352; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #212121,
      #9c9fa2
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #212121,
      #9c9fa2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .title {
    font-size: 50px;
  }
  .subtitle {
    font-size: 25px;
  }
  .highlight {
    background-color: #fff;
    color: #000 !important;
    padding: 5px 5px 5px 5px;
    max-width: 350px;
  }

  .btn-info {
    background: rgba(0, 0, 0, 1);
    color: white;
    border: #000 solid 1px;
    padding: 8px 15px 8px 15px;
    border-radius: 0.2rem;
    transition: 0.2s;
  }
  .btn-info:hover {
    opacity: 0.7;
    color: white;
    background-color: rgba(84, 89, 95, 1);
    border-color: #54595f;
    text-decoration: none;
    cursor: pointer;
  }
  .btn-info:focus {
    opacity: 0.7;
    color: #54595f;
    background-color: #ffffff !important;
    border-color: #000;
    box-shadow: 0 0 0 0.2rem #54595f;
    text-decoration: none;
    cursor: pointer;
  }
  .devider {
    margin-top: 2%;
    margin-bottom: 2%;
  }
</style>

